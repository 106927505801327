<template>

  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="4"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
                  cols="12"
                  md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                      v-model="searchKey"
                      class="d-inline-block mr-1"
                      placeholder="搜索关键字..."
              />
            <b-button

                      variant="primary"
                      :to="{ name: 'apps-stockinbound-list'}"
              >
                <span class="text-nowrap">查询</span>
              </b-button>
            </div>
          </b-col>

          <!--serach by create_time-->
          <b-col
                  cols="12"
                  md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <flat-pickr
                      id="searchByTime"
                      v-model="searchByTime"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d'}"
                      class="form-control"
                      placeholder="搜索时间"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              hover
              small
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(err_id)="row">
          <b-form-checkbox
                  v-model="row.detailsShowing"
                  @change="row.toggleDetails"
                  class="checkbox-hidden"
          >
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="row.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="row.detailsShowing==false"
            />
          </b-form-checkbox>
          #{{ (row.item.err_id)}}
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('errorinfo_type', data.item.errorinfo_type)}}
        </template>

        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time)}}
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                      md="2"
                      class="mb-1"
              >
                <strong>访问地址 : </strong>{{ row.item.url }}
              </b-col>
              <b-col
                      md="2"
                      class="mb-1"
              >
                <strong>请求参数 : </strong>{{ row.item.params }}
              </b-col>

              <b-col
                      md="8"
                      class="mb-1"
              >
                <strong>错误内容 : </strong>{{ row.item.err_info }}
              </b-col>
            </b-row>
          </b-card>
        </template>





        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
                  @click="changeState(data.item)"
          >
            <b-badge
                    pill
                    :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{getCodeLabel('state', data.item.state)}}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
    <!--    <template #cell(actions)="data">
          <b-dropdown
                  variant="link"
                  no-caret
          >

            <template #button-content>
              <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-errorinfo-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-errorinfo-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>-->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
  import errorinfoUseList from './errorinfoUseList'
  import errorinfoStore from './errorinfoStore'

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
    },
    methods: {
      changeState: function(data) {
        const state = 1 - data.state
        store.dispatch('errorinfo/state', {id: data.id, state: state}).then(res => {
          data.state = state
        })
      },
    },
    setup() {
      // Register module
      if (!store.hasModule('errorinfo')) store.registerModule('errorinfo', errorinfoStore)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule('errorinfo')) store.unregisterModule('errorinfo')
      })

      const {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        searchByTime,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,

        // UI
      } = errorinfoUseList()

      return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        searchByTime,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,

        // Filter
        avatarText,
        getCodeOptions,
        getCode,
        getCodeLabel,
        getCodeColor,
        // UI
        toTime
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

